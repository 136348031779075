// flow.php
.flow {
	.case {
		.list {
			list-style: square;
			margin-left: 1rem;
		}
	}
	.flow {
		&__item {
			position: relative;
			margin-bottom: 7rem;
			padding: 5rem 3rem;
			background-color: #fff;
			p.nor {
				line-height: 2rem;
			}
			&:last-of-type {
				margin-bottom: 3rem;
			}
		}
		&__item:after {
			content: "";
			background-image: url(../img/flow_arrow.svg);
			width: 64px;
			height: 28px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -4rem;
		}
		&__item:last-of-type::after {
			display: none;
		}
		&__title {
			display: flex;
			// align-items: center;
			justify-content: center;
		}
		&__number {
			position: absolute;
			top: -4rem;
			left: -2rem;
			font-size: 4.25rem;
			font-family: "Montserrat", sans-serif;
			color: #fff;
			background-color: $primary;
			width: 120px;
			height: 120px;
			text-align: center;
			line-height: 120px;
		}
		@media (max-width: 576px) {
			&__number {
				font-size: 2.25rem;
				top: -2rem;
				left: 0;
				width: 65px;
				height: 65px;
				line-height: 65px;
			}
			&__item {
				padding: 5rem 1rem;
			}
		}
	}

	.care {
		&__wrap {
			background-image: url(../img/flow_care.png);
			background-position: center;
			background-size: cover;
			color: #fff;
			position: relative;
		}
		&__wrap:before {
			content: "";
			background-color: rgba($color: #333, $alpha: 0.6);
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
