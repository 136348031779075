.contact {
  .seminer {
    &__wrap {
      background-color: $secondary;
      margin-bottom: 2rem;
    }
    &__item {
      position: relative;
      padding: 3rem;
      p.nor {
        line-height: 2rem;
      }
      &:last-of-type {
        margin-bottom: 3rem;
      }
    }
    &__number {
      position: absolute;
      top: -4rem;
      left: -2rem;
      font-size: 2.25rem;
      font-family: "Montserrat", sans-serif;
      color: #fff;
      background-color: #01421d;
      width: 85px;
      height: 85px;
      text-align: center;
      line-height: 85px;
    }
    &__desc {
      li {
        position: relative;
        margin-bottom: 0.8rem;
        line-height: 1.8;
        padding-left: 1rem;
      }
      li:before {
        background-image: url(../img/check.svg);
        content: "";
        width: 18px;
        height: 14px;
        position: absolute;
        width: 18px;
        top: 10px;
        left: -10px;
      }
    }
    @media (max-width: 992px) {
      &__wrap {
        margin-bottom: 5rem;
      }
      &__wrap:nth-of-type(2) {
        margin-bottom: 1rem;
      }
    }
    @media (max-width: 576px) {
      &__number {
        left: 0;
      }
    }
  }
  .tel {
    &__wrap {
      background-image: url(../img/contact_tel.png);
      background-position: center;
      background-size: cover;
      color: #fff;
      position: relative;
    }
    &__wrap.line {
      background-image: url(../img/contact_line.png);
    }
    &__wrap:before {
      content: "";
      background-color: rgba($color: #333, $alpha: 0.6);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__inner {
      .main-heading.min {
        font-size: 2.6rem;
      }
      .main-heading.jp {
        font-size: 1.5rem;
        letter-spacing: 0.3rem;
        font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic",
          "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ,
          sans-serif;
      }
    }
  }
  &-form {
    br {
      display: none;
    }
    p {
      margin-bottom: 0;
    }
    label {
      line-height: 1.6;
      display: block;
    }
  }
}
