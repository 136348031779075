.archive {
	.main-heading {
		font-size: 2.5rem;
		word-break: keep-all;
	}

	.news__title,
	.news__date {
		line-height: 1.6;
	}
	.news__eyecatch {
		height: 300px;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
	@media (max-width: 768px) {
		.news__date {
			margin-bottom: 0;
		}
	}

	.news {
		&__nav {
			margin-bottom: 2rem;
		}
		&__tab {
			background-color: #fff;
			color: $info;
			padding: 0.5rem 1rem;
			text-align: center;
			font-size: 1rem;
			width: calc(100% / 4);
			border: 1px solid $primary;
			border-right: 0;
			&:last-of-type {
				border-right: 1px solid $primary;
			}
			&:hover {
				background-color: $primary;
				color: #fff;
			}
		}
		&__tab.active {
			background-color: $primary;
			color: #fff;
		}
		&__content {
			list-style: none;
		}
		&__content ol {
			list-style: none;
		}
		&__content ol a {
			border-bottom: 1px solid #cdd6dd;
			display: flex;
			align-items: center;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}

		@media (max-width: 992px) {
			&__tab {
				width: calc(100% / 2);
				border: 0;
				border-right: 1px solid $primary;
				border-bottom: 1px solid $primary;
				&:nth-of-type(1) {
					border-top: 1px solid $primary;
					border-left: 1px solid $primary;
				}
				&:nth-of-type(2) {
					border-top: 1px solid $primary;
				}
				&:nth-of-type(3) {
					border-left: 1px solid $primary;
				}
			}
			&__content ol a {
				flex-direction: column;
				align-items: start;
			}
			&__date {
				margin-bottom: 0;
			}
			&__title {
				line-height: 1.6;
			}
		}
	}
}

.single {
	max-width: 100%;
	.breadcrumb {
		display: none;
	}

	& .content {
		& a {
			text-decoration: none;
			color: #0000ff;
		}
		& .wp-caption {
			max-width: 100%;
		}
	}
	aside {
		a {
			color: initial !important;
		}
	}

	@media (max-width: 991px) {
		.hero-child__inner {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}
	}
	@media (max-width: 576px) {
		main {
			margin-top: 2rem;
		}
	}
}

aside {
	.case-list {
		line-height: 2;
	}
}
