html {
    scroll-behavior: smooth;
    @media (max-width: 576px) {
        font-size: 0.875rem;
        line-height: 2.4rem;
    }
}
body {
    font-size: 1rem;
    color: $info;
    letter-spacing: 0.1rem;
    line-height: 2.8rem;
    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic",
        "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, sans-serif;
    @media (max-width: 576px) {
        // line-height: 1.6rem;
    }
}
img {
    max-width: 100%;
    height: auto;
}
a {
    color: inherit;
}
a:hover {
    text-decoration: none;
    opacity: 0.8;
    color: inherit;
}
button,
.btn {
    outline: none;
    border: none;
    &:focus {
        outline: none;
    }
    &:hover {
        opacity: 0.8;
    }
}
:focus {
    outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: normal;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin-bottom: 0;
    line-height: inherit;
}

.lh-nor {
    line-height: 1.6;
}

.lh-lar {
    line-height: 2;
}

//Main Heading
.main-heading {
    font-size: 3.75rem;
    font-weight: 200;
    line-height: 1.4;
    letter-spacing: 0.5rem;
    margin-bottom: 1.5rem;
    font-family: "Montserrat", sans-serif;
    @media (max-width: 576px) {
        font-size: 2.5rem;
        padding-top: 10px;
    }
}

.main-heading.mh-small {
    font-size: 2.8rem;
}

section {
    margin-bottom: 6rem;

    &:last-of-type {
        margin-bottom: 3rem;
    }
    &.cta {
        margin-bottom: 0 !important;
    }
    @media (max-width: 768px) {
        margin-bottom: 3rem;
        &:last-of-type {
            margin-bottom: 1.5rem;
        }
    }
}

.mhr {
    position: relative;
    top: -2.5rem;
    margin-bottom: -2.5rem;
}
.bgc {
    background-color: $secondary;
    padding-bottom: 5rem;
}
section.bgc {
    margin-bottom: 0;
}

//Sub Heading
.sub-heading {
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: 0.5rem;
    margin-bottom: 1.5rem;
    font-family: "Montserrat", sans-serif;
}

//Copy
.copy {
    line-height: 3rem;
    font-weight: bold;
    font-size: 1.125rem;
}

.thin {
    font-weight: 200;
}
.lar {
    font-size: 1.125rem;
}
.nor {
    font-size: 1rem;
}
.lh-nor {
    line-height: 1.6rem;
}
.fwb {
    font-weight: bold;
}
.fwn {
    font-weight: normal;
}
.serif {
    font-family: "Noto Serif JP", serif;
}
.sans {
    font-family: "Noto Sans JP", sans-serif;
}
.mon {
    font-family: "Montserrat", sans-serif;
}

.font-reset {
    font-family: initial;
}

ol,
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a#flow,
a#tel,
a#form,
a#vision,
a#company,
a#staff,
a#shop,
a#care {
    padding-top: 8rem;
    margin-top: -8rem;
    display: block;
}

//Main
main {
    margin-top: 8rem;
    margin-bottom: 8rem;
    @media (max-width: 576px) {
        margin-top: 6rem;
    }
}

//Footer
.cta {
    background-image: url(../img/footer_cta.png);
    background-size: cover;
    background-position: center;
    position: relative;
    .main-heading.min {
        font-size: 2.6rem;
    }
    .main-heading.jp {
        font-size: 1.5rem;
        letter-spacing: 0.3rem;
        font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic",
            "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ,
            sans-serif;
    }
    &:before {
        content: "";
        background-color: rgba($color: #333, $alpha: 0.5);
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &__wrap {
        height: 100%;
        text-align: center;
        padding: 2.9rem 1.5rem;
        background-color: rgba($color: $primary, $alpha: 0.9);
    }
    &__tel {
        font-size: 2rem;
        margin-bottom: 2rem;
        display: inline-block;
    }
    &__btn {
        position: absolute;
        bottom: 3rem;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    // @media(max-width:991px) and (min-width:768px){
    // 	&__wrap{

    // 	}
    // }
    @media (max-width: 576px) {
        &__wrap {
            padding: 1.9rem;
        }
    }
}
footer {
    @media (max-width: 576px) {
        margin-bottom: 5rem;
    }
}

//Float button
.float-btn {
    // opacity: 0;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 5;
    //   transition: all 0.2s;
    //   &:hover {
    //     transform: scale(1.1) translateX(-2px);
    //   }
    //   &.showBtn {
    //     opacity: 1;
    //   }
    @media (min-width: 577px) {
        &_pc {
            display: inline-block;
        }
        &_sp {
            display: none;
        }
    }
    @media (max-width: 576px) {
        top: initial;
        bottom: 0;
        width: 100%;
        left: 0;
        &_pc {
            display: none;
        }
        &_sp {
            width: 100%;
            display: inline-block;
        }
    }
}

.toTop {
    position: absolute;
    right: 0;
    bottom: -70px;
    z-index: 5;
    transition: all 0.2s;
    opacity: 0;
    &.show {
        opacity: 1;
    }
}

//Pagenation
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    position: relative;
    font-size: 1.2rem;
}
.pagination span,
.pagination a {
    display: block;
    width: auto;
    margin: 4px;
    padding: 8px;
    text-decoration: none;
    border: 1px solid $primary;
    text-align: center;
    line-height: 16px;
}
.pagination .pager {
    width: 32px;
}
.pagination a:hover,
.pagination .current {
    color: #fff;
    background-color: $primary;
}
.pagination a.prev {
    // margin-right: 16px;
    // color: $primary;
    // border: 1px solid $primary;
    // border-radius: 50%;
    // padding: 0.5rem;
    display: none;
}
.pagination a.next {
    // margin-left: 16px;
    // color: $primary;
    // border: 1px solid $primary;
    // border-radius: 50%;
    // padding: 0.5rem;
    display: none;
}
.pagination a.first,
.pagination a.last,
.pagination span.page_num {
    display: none;
}
