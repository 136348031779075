.access {
	.shop {
		&Slider.swiper-container {
			height: initial;
			position: relative;
			top: -5rem;
			margin-bottom: -5rem;
			margin-top: 8rem;
		}
	}
	.access {
		&__map {
			height: 0;
			overflow: hidden;
			padding-bottom: 50%;
			position: relative;
			iframe {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
			}
		}
		&__box {
			border-left: 1px solid $info;
			padding: 0 1rem;
			margin-bottom: 2rem;
		}
		&__img {
			width: 220px;
			height: auto;
		}
	}
	@media (max-width: 768px) {
		.shop {
			&Slider.swiper-container {
				margin-bottom: -5.5rem;
			}
		}
	}
}

.company {
	.vision {
		&__outer {
			margin-top: 10rem;
			padding-bottom: 4rem;
			background: linear-gradient(
				to right,
				$secondary 0%,
				$secondary 90%,
				#fff 90%,
				#fff 100%
			);
		}
		&__wrap {
			position: relative;
			top: -3rem;
			@media (max-width: 576px) {
				top: -2.5rem;
			}
		}
		&__text {
			line-height: 2;
		}
		&__box {
			background-color: #fff;
			position: relative;
			top: -9rem;
			margin-bottom: -6rem;
		}
	}
	.info {
		&__outer {
			margin-top: 10rem;
			padding-bottom: 4rem;
			background: linear-gradient(
				to right,
				$secondary 0%,
				$secondary 80%,
				#fff 80%,
				#fff 100%
			);
			@media (max-width: 768px) {
				background: linear-gradient(
					to right,
					$secondary 0%,
					$secondary 90%,
					#fff 90%,
					#fff 100%
				);
			}
			@media (max-width: 576px) {
				background: linear-gradient(
					to right,
					$secondary 0%,
					$secondary 100%,
					#fff 100%,
					#fff 100%
				);
			}
		}
		&__wrap {
			position: relative;
			top: -3rem;
			margin-bottom: -3rem;
			@media (max-width: 576px) {
				top: -2.5rem;
				margin-bottom: 0;
			}
		}
		p {
			line-height: 1.8;
		}
		ul li {
			margin-right: 1.5rem;
		}
		ul li:before {
			content: "・";
		}
	}
	.shop {
		&Slider.swiper-container {
			height: initial;
			position: relative;
			top: -5rem;
			margin-bottom: -5rem;
			margin-top: 8rem;
		}
	}
	.staff {
		&__hero {
			position: relative;
		}
		&__hero__text {
			position: absolute;
			right: 7vw;
			bottom: 7vw;
		}
		&__hero__text h1 {
			background-color: rgba($color: $primary, $alpha: 0.9);
			color: #fff;
			display: inline-block;
			padding: 0 1rem;
			margin-bottom: 1.5rem;
		}
		&__hero__text h2 {
			background-color: rgba($color: #fff, $alpha: 0.9);
			padding: 0.5rem 1rem;
		}
		&__profile {
			background-color: $secondary;
		}
		&__profile__img {
			width: 140px;
			height: auto;
		}
		&__profile__box {
			position: relative;
			top: -2rem;
			background-color: #fff;
		}
		&__profile__name {
			color: $primary;
			font-size: 1rem;
			font-weight: bold;
		}
		&__profile__content {
			font-size: 0.875rem;
			line-height: 1.6;
			ul li {
				margin-right: 1.5rem;
			}
			ul li:before {
				content: "・";
			}
		}
		@media (max-width: 576px) {
			&__hero__text {
				right: 2vw;
				bottom: 11vw;
			}
			&__hero__text h2 {
				font-size: 1rem;
				line-height: 1.4;
			}
		}
	}
}
