.home {
    .greeting {
        margin-bottom: 10rem;
        &__copy {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #fff;
            z-index: 3;
        }

        @media (max-width: 1200px) {
            &__copy {
                bottom: -3rem;
            }
            & .main-heading,
            .copy {
                display: inline-block;
            }
            &__wrap {
                position: relative;
                top: -3rem;
                z-index: 2;
                width: min-content;
                background-color: #fff;
                padding-top: 1rem;
                padding-right: 1rem;
                margin-bottom: -2rem;
            }
        }
        @media (max-width: 991px) {
            &__copy {
                position: static;
            }
        }
        @media (max-width: 576px) {
            &__wrap {
                width: 100%;
                .copy {
                    font-size: 1.1rem;
                }
            }
        }
    }

    .flow {
        // background-image: url(../img/home_flow.png);
        background-image: url(../img/hero01.png);
        background-size: cover;
        // background-position: left center;
        background-position: center;
        position: relative;
        padding-bottom: 3rem;
        &:before {
            content: "";
            background-color: rgba($color: #333, $alpha: 0.5);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .about {
        // position: relative;
        // top: -7rem;
        // margin-bottom: -19rem;
        margin-top: 5rem;
        &__box {
            background-color: rgba($color: $primary, $alpha: 0.9);
            color: #fff;
            padding-top: 5rem;
            padding-bottom: 5rem;
            text-align: center;
            //   width: 80%;
            //   position: relative;
            //   left: -10%;
            //   top: -25%;
        }
        @media (min-width: 768px) and (max-width: 1030px) {
            &__box {
                width: 100%;
            }
        }
        @media (max-width: 576px) {
            &__box {
                left: 0;
            }
            &__box.first {
                margin-bottom: -6rem;
            }
        }
    }
    .case {
        margin-bottom: 8rem;
        &__title {
            line-height: 1.6;
            font-size: 1.1rem;
        }
        &__img img {
            width: 100%;
            height: 285px;
            object-fit: cover;
        }
        &__desc {
            line-height: 1.6;
            font-size: 0.8rem;
        }
    }
    .service {
        &__row {
            @media (min-width: 1200px) {
                margin-bottom: 30px;
            }
        }
        & .row .col-md-6 {
            // padding-right: 1.5px;
            padding-right: 15px;
            padding-left: 15px;
            // padding-left: 1.5px;
        }
        &__text {
            font-size: 0.9rem;
            padding-left: 2rem;
            position: relative;
            &:before {
                content: "";
                background-color: #fff;
                height: 1px;
                width: 1rem;
                position: absolute;
                left: 0.5rem;
                top: 0.7rem;
            }
        }
        &__title {
            // font-weight: bold;
            letter-spacing: 0.3rem;
            background-color: rgba(1, 66, 29, 0.8);
            padding: 0 1rem;
            display: inline-block;
            font-size: 1.1rem;
        }
        &__bg {
            position: relative;
            color: #fff;
            z-index: 0;
            padding: 0.5rem;
            padding-top: 15rem;
            overflow: hidden;
        }
        &__bg:before {
            content: "";
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transition: all 0.4s;
        }
        &__bg.one {
            &:before {
                background-image: url(../img/top_service01.png);
            }
        }
        &__bg.two {
            &:before {
                background-image: url(../img/top_service02.png);
            }
        }
        &__bg.three {
            &:before {
                background-image: url(../img/top_service03.png);
            }
        }
        &__bg.four {
            &:before {
                background-image: url(../img/top_service04.png);
            }
        }
        &__bg.five {
            &:before {
                background-image: url(../img/top_service05.png);
            }
        }
        &__bg.six {
            &:before {
                background-image: url(../img/top_service06.png);
            }
        }
        &__bg.seven {
            &:before {
                background-image: url(../img/top_service07.png);
            }
        }
        &__bg.eight {
            &:before {
                background-image: url(../img/top_service08.png);
            }
        }
        p {
            line-height: 1.8;
            margin-top: 0.5rem;
        }
        &__bg:hover {
            &:before {
                transform: scale(1.1);
                opacity: 1;
            }
        }
        &__bg:after {
            z-index: -1;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-color: rgba($color: #333, $alpha: 0.6);
            background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                rgba(0, 0, 0, 0.7) 100%
            );
        }
        @media (max-width: 768px) {
            &__bg {
                padding-top: 7rem;
            }
        }
    }
    .news {
        &__nav {
            margin-bottom: 2rem;
        }
        &__tab {
            background-color: #fff;
            color: $info;
            padding: 0.5rem 1rem;
            text-align: center;
            font-size: 1rem;
            width: calc(100% / 3);
            border: 1px solid $primary;
            &:nth-of-type(n+4) {
                border-top: none;
            }
            &:hover {
                background-color: $primary;
                color: #fff;
            }
        }
        &__tab.active {
            background-color: $primary;
            color: #fff;
        }
        &__content {
            list-style: none;
        }
        &__content ol {
            list-style: none;
        }
        &__content ol a {
            border-bottom: 1px solid #cdd6dd;
            display: flex;
            align-items: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        &__title {
            line-height: 1.6;
        }

        @media (max-width: 992px) {
            &__tab {
                width: calc(100% / 2);
                border: 1px solid $primary;
                &:nth-of-type(n+3) {
                    border-top: none;
                }
            }
            &__content ol a {
                flex-direction: column;
                align-items: start;
            }
            &__date {
                margin-bottom: 0;
            }
        }
    }
}
