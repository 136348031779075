.therapy {
  .intro {
    margin-bottom: 3rem;
  }
  .example {
    &__wrap {
      position: relative;
      top: -11rem;
      margin-bottom: -8rem;
    }
    &__copy {
      background-color: #fff;
      padding: 2rem;
    }
    &__copy ul li {
      position: relative;
      padding-left: 1rem;
      line-height: 1.6;
      margin-bottom: 1.5rem;
      &:before {
        content: "・";
        position: absolute;
        left: -5px;
      }
    }
    &__badge {
      text-align: center;
    }
    &__badge__title {
      background-color: $primary;
      color: #fff;
      border-radius: 50%;
      width: 108px;
      height: 108px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      font-weight: bold;
    }
    @media (max-width: 991px) {
      &__wrap {
        position: static;
        margin-bottom: 0;
      }
    }
  }
  .recommend {
    &__text {
      background-color: #fff;
      position: relative;
      top: -2rem;
      margin-bottom: -5rem;
      padding: 3rem;
    }
    &__text ul li {
      position: relative;
      padding-left: 1rem;
      line-height: 1.6;
      margin-bottom: 1.5rem;
      &:before {
        content: "・";
        position: absolute;
        left: -5px;
      }
    }
    @media (max-width: 991px) {
      &__text {
        position: static;
        margin-bottom: 0;
      }
    }
  }
  .more {
    background-color: #f0f3f5;
    padding-top: 3rem;
    &__wrap {
      //   position: relative;
      //   top: -13rem;
      //   margin-bottom: -13rem;
    }
    &__label {
      color: #fff;
      background-color: rgba($color: $primary, $alpha: 0.9);
      display: inline-block;
      padding: 0 1rem;
    }
    &__text {
      background-color: #fff;
      padding: 2rem;
      margin-top: 2rem;
    }
    &__text ul li {
      position: relative;
      padding-left: 1rem;
      line-height: 1.6;
      margin-bottom: 1.5rem;
      &:before {
        content: "*";
        position: absolute;
        left: -5px;
      }
    }
    &__links {
      margin-bottom: 3rem;
    }
    &__links a {
      color: $primary;
    }
    @media (max-width: 991px) {
      &__wrap {
        position: static;
        margin-bottom: 0;
      }
    }
  }
}
