@import "swiper/swiper-bundle.css";
.swiper {
	&-container {
		height: 100vh;
		width: 100%;
		@media (max-width: 576px) {
			height: 60vh;
		}
	}
	&-slide-active .swiper-img,
	&-swiper-slide-duplicate-active .swiper-img,
	&-swiper-slide-prev .swiper-img {
		animation: zoomDown 12s linear 0s;
		animation-fill-mode: both;
	}
	&-slide {
		overflow: hidden;
		backface-visibility: hidden;
	}
	&-img {
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		// &:before {
		//   content: "";
		//   background: linear-gradient(to bottom, #333 0%, transparent 20%);
		//   // filter: blur(1px);
		//   position: absolute;
		//   top: -5px;
		//   left: -5px;
		//   right: -5px;
		//   bottom: -5px;
		//   z-index: -1;
		//   opacity: .2;
		// }
		&.hero01 {
			background-image: url(../img/hero01.png);
			// background-image: url(../img/hero01.png);
		}
		&.hero02 {
			background-image: url(../img/hero02.png);
		}
		&.hero03 {
			background-image: url(../img/hero03.png);
		}

		&.noZoom {
			animation: none;
		}
	}
	// &-img-rec {
	//   height: 100%;
	//   background-repeat: no-repeat;
	//   background-size: cover;
	//   background-position: center bottom;
	//   &.hero-rec01 {
	//     background-image: url(../img/hero01.jpg);
	//   }
	// }
	&-hero-progress-wrapper {
		position: absolute;
		z-index: 2;
		right: 0;
		bottom: 0;
		width: 200px;
		height: 75px;
		padding: 1rem 3rem;
		background: linear-gradient(
			to right,
			#3eb134 0%,
			#70be23 65%,
			#9bc915 100%
		);
	}
	&-hero-progress-inner {
		position: relative;
		// padding:1.5rem 1rem 2rem 1rem;
	}
	&-hero-progress {
		position: absolute;
		left: 0;
		bottom: -24px;
		width: 0;
		max-width: 100%;
		height: 2px;
		background: #fff;
		z-index: 5;
	}
	&-hero-progress-bg {
		position: absolute;
		left: 0;
		bottom: -24px;
		width: 100%;
		max-width: 100%;
		height: 2px;
		background: #c1db72;
		z-index: 4;
	}
	&-pagination {
		color: #fff;
		bottom: 0;
	}
	&-pagination-current {
		left: -20px;
		position: absolute;
		bottom: -38px;
		z-index: 5;
	}
	&-pagination-total {
		right: -20px;
		position: absolute;
		bottom: -38px;
		z-index: 5;
	}
	@media (max-width: 576px) {
		&-hero-progress-wrapper {
			width: 120px;
			height: 30px;
			padding: 0.5rem 3rem;
		}
		&-hero-progress,
		&-hero-progress-bg {
			bottom: -11px;
		}
		&-pagination-current,
		&-pagination-total {
			bottom: -23px;
			font-size: 0.75rem;
		}
	}
}

@keyframes zoomDown {
	0% {
		transform: scale(1.15);
	}
	100% {
		transform: scale(1);
	}
}

.swiper-button-prev,
.swiper-button-next {
	color: $primary;
}

.caseSlider.swiper-container {
	height: initial;
}
