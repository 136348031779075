.hero {
    position: relative;
    margin-bottom: 0;
    &:before {
        content: "";
        background-color: rgba($color: #333, $alpha: 0.3);
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        // @media (max-width: 576px) {
        // 	opacity: 0;
        // }
    }
    &__text {
        // background-color: $primary;
        z-index: 3;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-align: center;
        letter-spacing: 0.5rem;
        // @media (max-width: 576px) {
        // 	bottom: 10%;
        // 	transform: translate(-50%, 0);
        // }
        h1 {
            //   font-family: "Noto Serif JP";
            font-size: 1.375rem;
            margin-bottom: 1rem;
            //   font-weight: bold;
            //   line-height: 5rem;
            //   letter-spacing: 0.5rem;
            // font-weight: bold;
            font-weight: 500;
            //   text-shadow: 1px 1px 1px #707070;
            @media (max-width: 991px) {
                line-height: 4rem;
            }

            @media (max-width: 576px) {
                margin-bottom: 1rem;
                line-height: 2rem;
            }
        }
        h2 {
            //   letter-spacing: 6rem;
            //   margin-left: 0.5rem;
            font-size: 3rem;
            font-weight: 400;
            line-height: 1.6;

            @media (max-width: 767px) {
                font-size: 1rem;
                font-weight: 400;
                // display: none;
            }
        }
    }
}

.hero-child {
    position: relative;
    margin-bottom: 0;
    &__wrap {
        background: linear-gradient(
            to right,
            $primary 0%,
            $primary 95%,
            #fff 95%,
            #fff 100%
        );
    }
    &__inner {
        padding-top: 5rem;
        padding-bottom: 5rem;
        justify-content: flex-end;
        img {
            width: 100%;
        }
    }
    &__heading {
        color: #fff;
        position: relative;
        z-index: 2;
        h2 {
            @media (max-width: 560px) {
                white-space: nowrap;
            }
        }
    }

    &__img {
        width: 100%;
        height: 50vh;
        min-height: 320px;
        position: relative;

        &:after {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            background-color: #333;
            opacity: 0.4;
        }
        & img {
            object-fit: cover;
            max-height: 100%;
            height: 100%;
            width: 100%;
        }
    }
    &__text {
        text-align: center;
        padding: 1rem 5rem;
        background-color: #fff;
        z-index: 2;
        position: absolute;
        left: 10%;
        bottom: 0;

        h1 {
            color: $primary;
            font-style: italic;
            font-family: "Noto Serif JP";
            letter-spacing: 0.1rem;
        }
        h2 {
            color: #505050;
            font-size: 1.8rem;
            font-family: "Noto Serif JP";
            letter-spacing: 0.3rem;
        }
    }
    @media (max-width: 991px) {
        &__heading .main-heading {
            margin-bottom: 0;
        }
        &__heading .copy {
            margin-bottom: 1rem;
        }
        &__inner {
            padding-left: 1rem;
        }
    }
    @media (max-width: 576px) {
        &__img {
            @media (max-width: 576px) {
                min-height: initial;
                height: 40vh;
            }
        }
        &__text {
            padding: 1rem 3rem;
            left: 5%;
            h1 {
                font-size: 1.1rem;
            }
            h2 {
                font-size: 1.4rem;
            }
        }
    }
}
