@import "~jquery-drawer/scss/drawer.scss";

@media (min-width: 1340px) {
  .drawer {
    .logo {
      width: 150px;
      height: 108px;
      margin-bottom: 5px;
      display: none;
      &.showLogo {
        display: block;
      }
    }
    &-nav {
      position: fixed;
      top: 0;
      right: 0 !important;
      width: 100%;
      height: initial;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 5rem;
      padding-left: 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: initial;
      background-color: initial;
      transition:
        right 0.6s cubic-bezier(0.19, 1, 0.22, 1),
        background-color 0.2s,
        color 0.2s !important;
      &.heroHeight {
        color: $info;
        background-color: #fff;
        box-shadow: 0 3px 16px rgba(68, 68, 68, 0.1);
        .drawer-pc h1 {
          color: $info;
        }
        .drawer-menu-item {
          color: $info;
        }
        .drawer-menu-item:hover {
          color: $info;
        }
      }
    }
    &-pc h1 {
      color: #fff;
      letter-spacing: 0.1rem;
      transition: all 0.2s;
      &.show {
        font-size: 1.8rem;
      }
    }
    &-pc a {
      display: flex;
      align-items: center;
    }
    &-menu {
      display: flex;
    }
    &-menu-none {
      display: none;
    }
    &-menu-item {
      color: #fff;
      font-size: 1.125rem;
      position: relative;
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      letter-spacing: 0.1rem;
      padding: 0.75rem;
      &.show {
        padding: 0.6rem 0.5rem;
      }
      &:hover {
        color: #fff;
      }
    }
    &-hamburger-icon {
      display: none;
    }
    &-sp {
      display: none;
    }
    &-only {
      display: none;
    }
    &-hover {
      position: relative;
    }
    &-hover-content {
      a {
        font-size: 0.875rem;
      }
      // p {
      //   color: #707070;
      // }
    }

    &-hover-content.dHover {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 3rem;
      margin-top: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      & li {
        width: 230px;
        font-size: 0.875rem;
        height: auto;
        padding: 0.5rem;

        a {
          display: block;
          line-height: 2.2rem;
        }
      }
    }
    &-link.current {
      position: relative;
      &:after {
        position: absolute;
        border-top: solid 2px $primary;
        bottom: 5px;
        left: 0;
        right: 0;
        content: "";
        display: block;
        transition: all 0.3s ease;
        width: 100%;
      }
    }
  }

  .navigation_search_form {
    display: none;
    text-align: right;
    &.show {
      display: block;
    }
    & input {
      width: 250px;
    }
  }
}

@media (max-width: 1339px) {
  .hero,
  .hero-child {
    margin-top: 3rem;
  }
  .drawer {
    &-menu li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    &-menu-item {
      color: $primary;
      letter-spacing: 0.15rem;
    }
    &-pc {
      display: none;
      // width:150px;
    }
    &-hover-content {
      box-shadow: none !important;
      padding-top: 0 !important;
      // margin-top: -15px;
      img {
        display: none;
      }
      li {
        font-size: 1rem;
        border: none !important;
        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
      li a p {
        margin-bottom: 0;
        padding: 0.25rem;
        font-size: 0.9rem;
        &:before {
          content: "-";
          margin-right: 0.5rem;
        }
      }
    }
    &--right &-nav {
      right: -80%;
    }
    &-nav {
      padding-top: 5px;
      width: 80%;
      padding-left: 1rem;
      color: $primary;
      border: 3px solid $primary;
      letter-spacing: 0.15rem;
    }
    .biz-about,
    .biz-service {
      pointer-events: none;
    }

    &-sp__wrapper {
      position: fixed;
      left: 0;
      width: 100%;
      top: 0;
      z-index: 4;
      padding: 0.5rem;
      padding-left: 1rem;
      color: $info;
      background-color: #fff;
      height: 65px;
      display: flex;
      align-items: center;
    }
    &-sp h1 {
      line-height: 2.4;
    }
    .logo {
      width: 75px;
      height: 54px;
      margin-top: 2px;
    }
    &-plus {
      // position: relative;
      float: right;
    }
    &-plus:after {
      // position: absolute;
      content: "+";
      // right: -13rem;
      // bottom: -4px;
      font-size: 1.5rem;
    }
    &-plus.open:after {
      content: "-";
    }
  }

  .navigation_search_form {
    margin-top: 1rem;
  }
}

.drawer {
  &--right.drawer-open &-hamburger {
    right: 0;
    top: 5px;
  }

  &-menu-item:hover {
    text-decoration: none !important;
  }
  &-hover-content {
    list-style: none;
    padding-left: 2rem;
    display: none;
  }
}

@media (max-width: 1250px) and (min-width: 1000px) {
  .drawer-hover,
  .drawer-link.current {
    position: static;
  }
  .drawer-menu-item:hover:after,
  .drawer-link.current:after {
    display: none;
  }
}

.underHero {
  .drawer-hamburger-icon:before,
  .drawer-hamburger-icon:after {
    background-color: $primary;
  }
}
.drawer-hamburger-icon,
.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  background-color: $primary;
}

.drawer--right.drawer-open .drawer-nav,
.drawer--right .drawer-hamburger,
.drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer-hamburger-icon,
.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  height: 2px;
}
span.drawer-hamburger-icon {
  height: 1.5px;
}
