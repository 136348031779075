@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Serif+JP:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Serif+JP:wght@300;400;500;600;700;900&display=swap");

@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");
@import "scss/bootstrap.scss";
@import "scss/drawer.scss";
@import "scss/animate.css";
@import "scss/breadcrumb.scss";
@import "scss/buttons.scss";
@import "scss/swiper.scss";

@import "scss/common.scss";
@import "scss/hero.scss";
@import "scss/home.scss";
@import "scss/service.scss";
@import "scss/about.scss";
@import "scss/contact.scss";
@import "scss/door.scss";
@import "scss/anime.scss";
@import "scss/archive-single.scss";
@import "scss/therapy.scss";
