.door {
	position: relative;
	& .container {
		position: relative;
		z-index: 2;
	}
	&__logo {
		position: absolute;
		bottom: -6rem;
		// top:-12rem;
		left: -10px;
		z-index: 1;
		@media (max-width: 576px) {
			left: 0;
			bottom: -9vw;
		}
	}
	&__logo.rec {
		bottom: -5vw;
		@media (max-width: 991px) {
			left: 0;
			bottom: -9vw;
		}
		@media (max-width: 576px) {
			bottom: -4vw;
		}
	}
	&__wrap {
		position: relative;
	}
	&__img {
		width: 100%;
		transition: transform 0.2s;
		&:hover {
			transform: scale(1.1);
		}
	}
	&__link {
		overflow: hidden;
		display: block;
	}
	&__label {
		letter-spacing: 0.1rem;
		position: absolute;
		bottom: -2rem;
		left: 50%;
		transform: translateX(-50%);
		width: 80%;
		text-align: center;
		z-index: 2;
		// background-color: $primary;
		background-color: rgba($color: $primary, $alpha: 0.9);
		color: #fff;
		transition: transform 0.2s;
		font-size: 1.2rem;
		@media (max-width: 767px) {
			font-size: 0.9rem !important;
			// padding: 0.5rem 1rem;
			bottom: -1.5rem;
		}
		@media (max-width: 576px) {
			line-height: 1.4rem;
			width: 95%;
		}
	}
	&__label-inner {
		margin: 0.5rem;
		padding: 0.5rem;
		border: 1px solid #fff;
	}
}
